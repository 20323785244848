export const applications = [
  {
    name: "OPI Rete Strategy",
    icon: 'opi.png',
    link: 'https://impianti.retestrategy.com'
  },
  {
    name: "CAP Rete Strategy",
    icon: 'opi.png',
    link: 'https://report.costoappalti.online'
  },
  {
    name: "HR Samag Holding",
    icon: 'sh.png',
    link: 'https://hr.samagholding.com'
  },
  {
    name: "Audit Samag Holding",
    icon: 'sh.png',
    link: 'https://audit.samagholding.com'
  },
  {
    name: "Intranet Samag Holding",
    icon: 'sh.png',
    link: 'https://intranet.samagholding.com'
  },
  {
    name: "Intranet CBS Lavoro",
    icon: 'cbslavoro.png',
    link: 'https://intranet.cbslavoro.it'
  },
  {
    name: "Ticket CBS Lavoro",
    icon: 'cbslavoro.png',
    link: 'https://ticket.cbslavoro.it'
  },
  {
    name: "Mailing Rete Strategy",
    icon: 'opi.png',
    link: 'https://mailing.retestrategy.com/admin/login'
  },
  {
    name: "Human Resource Activities",
    icon: 'hra.png',
    link: 'https://hra.cbslavoro.it/'
  }
];

export const tools = [
  {
    name: "Outlook",
    icon: 'outlook.svg',
    link: 'https://outlook.office.com/mail/'
  },
  {
    name: "Teams",
    icon: 'teams.svg',
    link: 'https://teams.microsoft.com/'
  },
  {
    name: "Calendar",
    icon: 'calendar.svg',
    link: 'https://outlook.office.com/calendar/view/month'
  },
  {
    name: "Onedrive",
    icon: 'onedrive.svg',
    link: 'https://onedrive.live.com/'
  },
  {
    name: "Contatti",
    icon: 'contacts.svg',
    link: 'https://outlook.office.com/people/'
  },
  {
    name: "To-Do",
    icon: 'todo.svg',
    link: 'https://to-do.office.com/'
  },
  {
    name: "Password Generator",
    icon: 'password-generator.png',
    link: 'https://password.servizi.digital/'
  },
  {
    name: "Secrets Service Manager",
    icon: 'secret.png',
    link: 'https://secret.servizi.digital/'
  },
  {
    name: "Invio Richieste",
    icon: 'request.png',
    link: 'https://richieste.samag.tech'
  },
];