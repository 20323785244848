<template>
  <form
    action="https://www.google.com/search"
    target="_blank"
    class="search mx-auto"
  >
    <div class="input-group mb-3 border rounded-pill p-2 border rounded-pill">
      <span
        class="input-group-text bg-transparent border-0"
      >
        <font-awesome-icon icon="magnifying-glass" />
      </span>

      <input
        type="text"
        name="q"
        placeholder="Cerca su Google"
        class="form-control border-0"
      >
    </div>
  </form>
</template>

<script setup>

</script>

<style scoped>
.search {
  max-width: 650px;
}
.input-group-text {
  color: var(--bs-gray-400);
}

.form-control:focus {
  box-shadow: none;
}
</style>