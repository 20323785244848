<template>
  <div
    class="application--item m-2 text-center"
    v-for="(app, index) in state.applicationList"
    :key="index"
  >
    <ApplicationItem :app="app"/>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import ApplicationItem from './Item.vue'
import { reactive } from 'vue'

const props = defineProps({
  applications: {
    type: Object,
    required: true
  }
});

const state = reactive({applicationList: props.applications})
</script>


<style>
.application--item {
  width: 150px;
  height: 100px;
  font-size: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.tools-wrap .application--item {
  width: 100px;
  height: 80px;
  font-size: 10px;
}

.tools-wrap .app--link {
  height: 100%;
}


</style>