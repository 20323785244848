<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <img
          class="d-block mx-auto mb-4 logo"
          alt="EAS logo"
          :src="getImgUrl(userLogo)"
        />

        <div class="search-wrap">
          <Search />
        </div>

        <div class="applications-wrap d-flex justify-content-center flex-wrap mx-auto">
          <div
            v-if="!user"
            class="not-auth"
          >

            <div
              class="alert alert-info text-center mt-4 mb-5 w-100"
              role="alert"
            >
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
              Effettua il login e accedi alle tue applicazioni con un semplice click.
              <router-link :to="{name: 'Login'}" class="alert-link">Clicca qui per il login</router-link>.
            </div>
          </div>

          <ApplicationList
            v-else
            :applications="applications"
          />

        </div>

        <div
          class="tools-wrap"
          v-if="showTools"
          v-click-outside
        >
          <ApplicationList :applications="tools"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Components
import Search from '../components/Search.vue';
import ApplicationList from './../components/Applications/List.vue'
// -------------------------------

import { computed } from 'vue';
import { useStore } from 'vuex';
import { applications, tools } from '../data/applications';

const store = useStore();

const showTools = computed(() => store.getters.showTools);

const user = computed(() => store.getters['auth/getUser']);

const userDomain = store.getters['auth/getUserDomain']

const userLogo = computed(() => store.getters['auth/getHomeLogo'](userDomain))

function getImgUrl(logo) {
  return require('./../assets/images/companies/'+logo)
}

const vClickOutside = {
  mounted(el) {
    const rightNav = document.querySelector('.btn-toolbox-wrap');

    document.addEventListener('click', (ev) => {
      const inside = el.contains(ev.target)

      if(!inside && ! ev.composedPath().includes(rightNav)) {
        store.commit('setShowTools', false)
      }
    })
  }
}
</script>

<style scoped>
.logo {
  max-width: 250px;
  max-height: 150px;
}
.applications-wrap {
  max-width: 850px;
}
.tools-wrap{
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0px 0px 4px 1px #ddd;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.5rem 0rem;
  position: absolute;
  top: 58px;
  right: 10px;
}
</style>