<template>
  <a
    :href="app.link"
    target="_blank"
    class="app--link text-decoration-none"
  >
    <figure v-if="app.icon" class="app--icon_wrap">
      <img
        :src="getImgUrl(app.icon)"
        :alt="app.name"
        class="img-fluid app--icon"
      >
    </figure>

    <span class="d-inline-block pt-2 app--title">{{ app.name }}</span>
  </a>
</template>

<script>
export default {
  props: {
    app: Object,
  },
  setup() {

    function getImgUrl(pic) {
      return require('./../../assets/images/applications/'+pic)
    }

    return {
      getImgUrl
    }
  }
}

</script>

<style scoped>
.app--icon_wrap {
  align-items: center;
  background-color: var(--bs-gray-100);
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  margin: 0rem auto;
  transition: all .2s linear;
}

.app--icon {
  max-height: 20px;
}

.app--title {
  color: var(--bs-gray-800);
  font-size: inherit;
  font-weight: 500;
  transition: all .2s linear;
  width: 120px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

.app--link:hover .app--icon_wrap {
  background: var(--bs-gray-200);
}

.app--link:hover .app--title {
  color: var(--bs-dark);
  white-space: normal;
}
</style>
